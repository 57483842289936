import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';


class PanHeader extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            nav : window.location.href.substring(window.location.href.lastIndexOf('/')),
        }
    }

    navToExamlookup = () => {
        this.setState({
            nav : '/examlookup',
        })
    }

    navToTranscript = () => {
        this.setState({
            nav : '/transcript',
        })
    }

    render(){
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
         //console.log('header token: ', idToken);

        return (
            <div className="panHeader">
                <div className="block1">
                    <img src="/PANW-new-logo.png" alt="logo" />
                </div>
                <div className="block2">
                    <p>LMS-Sync Admin UI</p>
                </div>
                { (idToken && idToken.idToken) ?
                <div className="block3">
                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <Link className={ this.state.nav !== '/transcript' ? "nav-link active" : "nav-link"} to="/examlookup" onClick={this.navToExamlookup}>Examlookup</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={ this.state.nav === '/transcript' ? "nav-link active" : "nav-link"} to="/transcript"onClick={this.navToTranscript} >User Attributes</Link>
                        </li>
                    </ul>
                </div>
                :
                <div className="block3">
                </div>
                }
            </div>
        );
    }
  }

  export default PanHeader;
