import React from 'react';
import './historyForTranscript.css';
import { trackPromise } from 'react-promise-tracker';

class history extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            TranscriptId : null,
            AccountId : null,
            CreatedOn : null,
            history : null
        }
    }

    async componentWillReceiveProps(nextProps){
        // console.log('Render Transcript history');
        let propData = nextProps.historyData;
        if(propData === null) {
            this.setState({
                TranscriptId : null,
                AccountId : null,
                CreatedOn : null,
                history : null
            })
            return;
        }
        
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
        if(propData.TranscriptId !== this.state.TranscriptId) {
            this.setState({
                TranscriptId : null,
                AccountId : null,
                CreatedOn : null,
                history : null
            })

            await trackPromise(
                fetch('/admin-api/changeLogs?source=transcripts,accounts&sourceId=' + propData.TranscriptId + ',' + propData.AccountId, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                        },
                    })
                    .then(res => res.json())
                    .then(data => this.setState({
                        TranscriptId : propData.TranscriptId,
                        AccountId: propData.AccountId,
                        CreatedOn : propData.CreatedOn,
                        history : data.data
                    }, console.log("ChangeLogs + data.data")))
            )
            
            this.convertTime();
            this.filt();
        }
    }

    convertTime(){
        let hisData = this.state.history;
        for(let ele of hisData){
            if(ele.ChangedOn) {
                let date = new Date(ele.ChangedOn);
                ele.ChangedOn = date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
            }
        }
        this.setState({history : hisData});
    }

    filt(){
        const hisToShow = ['Test', 'DateTaken', 'Email', 'FirstName', 'LastName', 'DoNotLoad', 'PassFail', 'SFDCAccreditationId', 'ExpirationDate', 'MarkForDelete', 'Comments']; //Comments will include comments from account table and Transcript table
        let hisData = this.state.history;
        let newHisData = []
        for(let i = 0; i < hisData.length; i++) {
            let changeLogs = hisData[i].ChangeLog;

            let newChangeLogs = [];
            for(let ele of changeLogs){
                if(hisToShow.includes(ele.field)) newChangeLogs.push(ele);
            }

            if(newChangeLogs.length !== 0) {
                hisData[i].ChangeLog = newChangeLogs;
                newHisData.push(hisData[i]);
            }

        }
        this.setState({
            history : newHisData,
        })
    }

    render(){
        const history = this.state.history;
        const CreatedOn = this.state.CreatedOn;
        const trueFalseCol = ['DoNotLoad', 'MarkForDelete'];

        return(
            <div className="modal fade" id="history" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Change History</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        { 
                        history ?
                        history.length === 0 ? 
                        <div>
                            <div className="alert alert-primary">Record was created - On {CreatedOn} (PST)</div>
                            <h1>No History Found...</h1>
                        </div>
                        :
                        <div>
                            <div className="alert alert-primary">Record was created - On {CreatedOn} (PST)</div>
                            {history.map((ele, index) =>
                                <table key={index} className="table table-sm">
                                <thead className="thead-light">
                                    <tr className="row">
                                        <th scope="col" className="col-4">Change</th>
                                        <th scope="col" className="col-4">On { ele.ChangedOn ? ele.ChangedOn.replace(',', '') : "N/A"} (PST)</th>
                                        <th scope="col" className="col-4">By { ele.ChangedBy ? ele.ChangedBy : "N/A"}</th>
                                    </tr>
                                </thead>
                                <thead className="thead-light">
                                    <tr className="row">
                                        <th scope="col" className="col-4">Change Field</th>
                                        <th scope="col" className="col-4">From</th>
                                        <th scope="col" className="col-4">To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ele.ChangeLog.map((e,i) =>
                                        !trueFalseCol.includes(e.field) ?                                      
                                        <tr key={i} className="row">
                                            <th className="his_th col-4">
                                                { 
                                                    ((e.newValue && typeof e.newValue !== "object" && e.newValue.toString().includes('Ticket No:')) || 
                                                    (e.oldValue && typeof e.oldValue !== "object" && e.oldValue.toString().includes('Ticket No:'))) ? "Ticket Number" : e.field
                                                }
                                            </th>
                                            <th className="his_th col-4">{ !e.oldValue ? 'N/A' : typeof e.oldValue !== "object" ? e.oldValue.toString().includes('Ticket No:') ?  e.oldValue.toString().replace('Ticket No:', '') : e.oldValue.toString() : e.oldValue.value }</th>
                                            <th className="his_th col-4">{ !e.newValue ? 'N/A' : typeof e.newValue !== "object" ? e.newValue.toString().includes('Ticket No:') ?  e.newValue.toString().replace('Ticket No:', '') : e.newValue.toString() : e.newValue.value }</th>
                                        </tr>
                                        :
                                        <tr key={i} className="row">
                                            <th className="his_th col-4">{e.field.replace('_', ' ')}</th>
                                            <th className="his_th col-4">{e.oldValue === 0 ? 'False' : 'True'}</th>
                                            <th className="his_th col-4">{e.newValue === 0 ? 'False' : 'True'}</th>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            )}
                        </div>
                        :
                        <h1>Please wait...</h1>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default history;