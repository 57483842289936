import React from 'react';
import './addForLookup.css';
import { trackPromise } from 'react-promise-tracker';
import { toast } from "react-toastify";

class Add extends React.Component {

    constructor(){
        super();
        this.state = {
            fieldset1 : false,
            fieldset2 : false,
            fieldset3 : false,
            ShortTitle: "",
            Comment: "",
            LevelupCourseCode: "",
            BeaconCourseCode: "",
            BigQueryCourseCode: "",
            Validity: "",
            ShowScore: "",
            IsActive: true,
            commentsLength : 0,
            buttonStatus: false,
        }
        this.addModal = React.createRef();
        this.ShortTitle = React.createRef();
        this.LevelupCourseCode = React.createRef();
        this.BeaconCourseCode = React.createRef();
        this.BigQueryCourseCode = React.createRef();
        this.Validity = React.createRef();
        this.Comment = React.createRef();
        this.refresh = this.refresh.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event){
        event.preventDefault();

        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
        let data = new FormData(event.target);
        let jsonData =  {
            "ShortTitle": null,
            "Comment": null,
            "LevelupCourseCode": null,
            "BeaconCourseCode": null,
            "BigQueryCourseCode": null,
            "Validity": null,
            "ShowScore": 0,
            "IsActive": 0,
            "SourceApplication": "Admin_UI",
            "CreatedBy": idToken.idToken.claims.email
        };
        for (var [key, value] of data.entries()) {
            jsonData[key] = value.trim();
        }
        this.clearValidation();
 
        if(this.validateForm(jsonData)){
            this.setState({ buttonStatus: true });
            await trackPromise(
                fetch('/admin-api/lookup', {
                    method: 'POST',
                    body: JSON.stringify(jsonData),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                    }
                }).then(response => response.json())
                .then(async (data) => {
                    if(!data.success) {
                        toast.error('Error!',{
                            position: "top-right",
                            autoClose: 3000,
                            progress: undefined,
                        });
                        if(data.message === "Invalid_bigquery_code"){
                            this.BigQueryCourseCode.current.classList.add("is-invalid");
                            this.BigQueryCourseCode.current.nextElementSibling.innerHTML = 'Dupicate Exam Code found. Please try another one.';
                        }
                        if(data.message === "Invalid_ShortTitle_Comment"){
                            this.ShortTitle.current.classList.add("is-invalid");
                            this.ShortTitle.current.nextElementSibling.innerHTML = 'Duplicate Short Title and Comment combo found. Please try another one';
                            this.Comment.current.classList.add("is-invalid");
                        }
                        this.setState({buttonStatus : false});
                        return;
                    }

                    // toast.success('Operation success!',{
                    //     position: "top-right",
                    //     autoClose: 1500,
                    //     progress: undefined,
                    // });
                    // await new Promise(resolve => setTimeout(resolve, 1500));
                    window.location.reload(false);                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                    toast.error('Error occured on Server!',{
                        position: "top-right",
                        autoClose: 3000,
                        progress: undefined,
                    });
                    this.setState({buttonStatus : false});
                })
            )
        }
    }

    clearValidation(){
        this.ShortTitle.current.classList.remove("is-invalid");
        this.ShortTitle.current.nextElementSibling.innerHTML = 'Please provide valid input.';
        // this.Validity.current.classList.remove("is-invalid");
        this.LevelupCourseCode.current.classList.remove("is-invalid");
        this.BeaconCourseCode.current.classList.remove("is-invalid");
        this.BigQueryCourseCode.current.classList.remove("is-invalid");
        this.BigQueryCourseCode.current.nextElementSibling.innerHTML = 'Please provide valid input.';
        this.Comment.current.classList.remove("is-invalid");
    }

    validateForm(formData){
        let [flag1, flag2, flag3, flag4, flag5] = [true, true, true, true, true];
        if(!formData.ShortTitle){
            this.ShortTitle.current.classList.add("is-invalid");
            flag1 = false;
        }

        // if(!formData.Validity){
        //     this.Validity.current.classList.add("is-invalid");
        //     flag2 = false;
        // }

        if(this.state.fieldset1 && !formData.LevelupCourseCode){
            this.LevelupCourseCode.current.classList.add("is-invalid");
            flag3 = false;
        }

        if(this.state.fieldset2 && !formData.BeaconCourseCode){
            this.BeaconCourseCode.current.classList.add("is-invalid");
            flag4 = false;
        }

        if(this.state.fieldset3 && !formData.BigQueryCourseCode){
            this.BigQueryCourseCode.current.classList.add("is-invalid");
            flag5 = false;
        }
        return flag1 && flag2 && flag3 && flag4 && flag5;
    }

    fieldChange(fieldId, event){
        if (event !== undefined) {
            if (fieldId === "fieldset1") {
                this.setState({
                    fieldset1: event.target.checked
                })
            } else if (fieldId === "fieldset2") {
                this.setState({
                    fieldset2: event.target.checked
                })
            } else if (fieldId === "fieldset3") {
                this.setState({
                    fieldset3: event.target.checked
                })
            } else if (fieldId === "BeaconCourseCode") {
                this.setState({
                    BeaconCourseCode: event.target.value
                })
            } else if (fieldId === "LevelupCourseCode") {
                this.setState({
                    LevelupCourseCode: event.target.value
                })
            } else if (fieldId === "BigQueryCourseCode") {
                this.setState({
                    BigQueryCourseCode: event.target.value
                })
            } else if (fieldId === "ShowScore") {
                this.setState({
                    ShowScore: event.target.checked ? 1 : 0
                })
            } else if (fieldId === "IsActive") {
                this.setState({
                    IsActive: event.target.checked ? 1 : 0
                })
            } else if (fieldId === "ShortTitle") {
                this.setState({
                    ShortTitle: event.target.value
                })
            } else if (fieldId === "Validity") {
                this.setState({
                    Validity: event.target.value
                })
            } else if (fieldId === "Comment") {
                const commentVal = event.target.value;
                const commentLength = commentVal.length;

                if(commentLength <= 255){
                    this.setState({
                        Comment: commentVal,
                        commentsLength : commentLength
                    })
                }
            }
        }
    }

    refresh(){
        this.clearValidation();
        this.setState({
            fieldset1 : false,
            fieldset2 : false,
            fieldset3 : false,
            ShortTitle: "",
            Comment: "",
            LevelupCourseCode: "",
            BeaconCourseCode: "",
            BigQueryCourseCode: "",
            Validity: "",
            ShowScore: "",
            IsActive: true,
            commentsLength : 0,
            buttonStatus: false,
        })
    }

    render() {
        const {fieldset1,fieldset2, fieldset3, ShortTitle, Comment, LevelupCourseCode, BeaconCourseCode, BigQueryCourseCode, Validity, ShowScore, IsActive, commentsLength, buttonStatus} = this.state;

        return (
            <div>
                <div className="operation">
                    <button type="button" className="btn btn-outline-success" data-toggle="modal" data-target="#exampleModalCenter" onClick={this.refresh}>
                        Add New
                    </button>
                </div>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" ref={this.addModal}>
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Add to Exam Lookup table</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form className="modal-body" onSubmit={this.handleSubmit} autoComplete="off">
                                <div className="form-group formUnit-shortTitle">
                                    <label htmlFor="shortTitle">Exam Short Title</label>
                                    <input type="text" className="form-control" name="ShortTitle" id="ShortTitle" maxLength="100" onChange={this.fieldChange.bind(this, "ShortTitle")} value={ShortTitle} ref={this.ShortTitle} />
                                    <div className="invalid-feedback">
                                        Please provide valid input.
                                    </div>
                                </div>
                                <hr/>
                                <div className="form-check formUnit-lvCode">
                                    <input className="form-check-input" type="checkbox" name="fieldset1" value="1" id="LevelupCourseCode" checked={fieldset1} onChange={this.fieldChange.bind(this, "fieldset1")}/>
                                    <label className="form-check-label" htmlFor="LevelupCourseCode">
                                        Level Up Course Code
                                    </label>
                                    <fieldset disabled = {fieldset1 ? "" : "disable"}> 
                                        <input type="text" className="form-control" name="LevelupCourseCode" maxLength="100" value={LevelupCourseCode} onChange={this.fieldChange.bind(this, "LevelupCourseCode")} ref={this.LevelupCourseCode}/>
                                        <div className="invalid-feedback">
                                            Please provide valid input.
                                        </div>
                                    </fieldset>
                                </div>
                                <hr/>
                                <div className="form-check formUnit-beCode">
                                    <input className="form-check-input" type="checkbox" name="fieldset2" value="1" id="BeaconCourseCode" checked={fieldset2} onChange={this.fieldChange.bind(this, "fieldset2")}/>
                                    <label className="form-check-label" htmlFor="BeaconCourseCode">
                                       Beacon Course Code
                                    </label>
                                    <fieldset disabled = {fieldset2 ? "" : "disable"}>
                                        <input type="text" className="form-control" name="BeaconCourseCode" maxLength="100" value={BeaconCourseCode} onChange={this.fieldChange.bind(this, "BeaconCourseCode")} ref={this.BeaconCourseCode}/>
                                        <div className="invalid-feedback">
                                            Please provide valid input.
                                        </div>
                                    </fieldset>
                                </div>
                                <hr/>
                                <div className="form-check formUnit-pvCode">
                                    <input className="form-check-input" type="checkbox" name="fieldset3" value="1" id="BigQueryCourseCode" checked={fieldset3} onChange={this.fieldChange.bind(this, "fieldset3")}/>
                                    <label className="form-check-label" htmlFor="BigQueryCourseCode">
                                        BigQuery Exam Code
                                    </label>
                                    <fieldset disabled = {fieldset3 ? "" : "disable"}>
                                        <input type="text" className="form-control" name="BigQueryCourseCode" maxLength="255" value={BigQueryCourseCode} onChange={this.fieldChange.bind(this, "BigQueryCourseCode")} ref={this.BigQueryCourseCode}/>
                                        <div className="invalid-feedback">
                                            Please provide valid input.
                                        </div>
                                    </fieldset>
                                </div>
                                <hr/>
                                <div className="form-row formUnit-Va">
                                    <div className="validity-col form-group col">
                                        <label htmlFor="Validity">Validity </label>
                                        <input className="form-control" type="number" min="0" max="24" list="Validity" name="Validity" onChange={this.fieldChange.bind(this, "Validity")} value={Validity} ref={this.Validity}/>
                                        <datalist id="Validity">
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                        </datalist>
                                        <div className="invalid-feedback">
                                            Please provide valid input.
                                        </div>
                                    </div>
                                    <div className="form-group col check">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="ShowScore" id="ShowScore" onChange={this.fieldChange.bind(this, "ShowScore")} checked={ShowScore} value="1"/>
                                            <label className="form-check-label" htmlFor="ShowScore">Show Score</label>
                                        </div>
                                    </div>
                                    <div className="form-group col check">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="IsActive" id="IsActive" onChange={this.fieldChange.bind(this, "IsActive")} checked={IsActive} value="1"/>
                                            <label className="form-check-label" htmlFor="IsActive">Is Active</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group comment">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Comment</span>
                                    </div>
                                    <textarea className="form-control" name="Comment" value={Comment} onChange={this.fieldChange.bind(this, "Comment")} aria-label="Comment" ref={this.Comment}></textarea>
                                    <div className="input-group-append">
                                        <span className="input-group-text">{commentsLength}/255</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal" disabled={buttonStatus}>Cancel</button>
                                    <input type="submit" value="Save to Add" className="btn btn-outline-primary" disabled={buttonStatus}></input>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Add;