import React from 'react';
import './editForTranscript.css'
import { trackPromise } from 'react-promise-tracker';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MergeNotification from '../notification/mergeNotification.jsx';
// import CloseButton from '../notification/CloseButton.jsx';

class EditT extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Email : "",
            DoNotLoad : false,
            MarkForDelete : false,
            Comments : "",
            FirstName : "",
            LastName : "",
            UniqueId : "",
            TicketNumber : "",
            CommentsLength : 0,
            EmailChange : false,
            buttonStatus: false,
            bodyData:null,
        }

        this.refreshPage = this.refreshPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Email = React.createRef();
        this.FirstName = React.createRef();
        this.LastName = React.createRef();
        this.UniqueId = React.createRef();
        this.TicketNumber = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('Render Transcript Edit');

        let obj = nextProps.editData;
        this.clearValidation();

        if (Object.keys(obj).length !== 0) {
            this.setState({
                Email : this.handleNull(obj.Email),
                FirstName : this.handleNull(obj.FirstName),
                LastName : this.handleNull(obj.LastName),
                UniqueId: this.handleNull(obj.UniqueId),
                DoNotLoad: obj.DoNotLoad ? true : false,
                MarkForDelete: obj.MarkForDelete ? true : false,
                Comments: this.handleNull(obj.Comments),
                CommentsLength : this.handleNull(obj.Comments).length,
                TicketNumber : "",
                EmailChange : false,
                buttonStatus: false,
                bodyData:null,
            })
        }
    }

    handleNull(val){
        if(val === null){
            return ""
        } else {
            return val
        }
    }

    clearValidation(){
        this.Email.current.classList.remove("is-invalid");
        this.Email.current.nextElementSibling.innerHTML = 'Please provide valid input.';
        this.FirstName.current.classList.remove("is-invalid");
        this.LastName.current.classList.remove("is-invalid");
        this.UniqueId.current.classList.remove("is-invalid");
        this.TicketNumber.current.classList.remove("is-invalid");
    }
    
    validateForm(formData){
        let [flag1, flag2, flag3, flag4] = [true, true, true, true];
        //const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9_-]+?\.[a-zA-Z]{2,10}(\.[a-zA-Z]{2,10})(\.[a-zA-Z]{2,10})?$/;
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
       
        if(formData.Email === "" || !emailRegex.test(formData.Email)){
            this.Email.current.classList.add("is-invalid");
            flag1 = false;
        }

        // if(formData.FirstName === ""){
        //     this.FirstName.current.classList.add("is-invalid");
        //     flag2 = false;
        // }

        // if(formData.Lastname === ""){
        //     this.LastName.current.classList.add("is-invalid");
        //     flag3 = false;
        // }

        if(formData.TicketNumber === "" && this.state.EmailChange) {
            this.TicketNumber.current.classList.add("is-invalid");
            flag4 = false;
        }

        return flag1 && flag2 && flag3 && flag4;
    }

    refreshPage() {
        window.location.reload(false);
    }

    async handleSubmit(event) {
        event.preventDefault();

        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        const data = new FormData(event.target);
        let bodyData = {
            "Email" : null,
            "FirstName": null,
            "LastName": null,
            "UniqueId": null,
            "TicketNumber": "",
            "DoNotLoad": 0,
            "MarkForDelete": 0,
            "Comments": null,
            "Source": "Admin_UI",
            "UpdatedBy" : idToken.idToken.claims.email,
            "TranscriptId" : this.props.editData.TranscriptId,
            "AccountId" : this.props.editData.AccountId,
        };
        for (var [key, value] of data.entries()) {
            bodyData[key] = value.trim();
            if(key === 'Email') bodyData[key] = bodyData[key].toLowerCase();
        }

        this.clearValidation();
        
        if(this.validateForm(bodyData)){
            this.setState({
                bodyData : bodyData,
                buttonStatus : true
            });
            await trackPromise(
                fetch('/admin-api/transcriptFormVerification', {
                    method: 'POST',
                    body: JSON.stringify(bodyData),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                    }
                }).then(response => response.json())
                .then(async (data) => {
                    if(data.success) {
                        return await this.sendUpdateForm();
                    }

                    if(data.message === 'Invalid_email'){
                        this.Email.current.classList.add("is-invalid");
                        this.Email.current.nextElementSibling.innerHTML = 'Dupicate email found.';
                        
                        toast.info(<MergeNotification 
                            // bodyData={bodyData} 
                            sendUpdateForm={()=>this.sendUpdateForm()}/>, 
                            { onClose: () => this.setState({buttonStatus: false}),
                            closeButton: true,
                            position: "top-center",
                            autoClose: false,
                            closeOnClick: false,
                            draggable: false,
                            progress: undefined,});
                    } else {
                        toast.error('Error occured on Server!',{
                            position: "top-right",
                            autoClose: 3000,
                            progress: undefined,
                        });
                        this.setState({buttonStatus : false});
                    }
                    }).catch((error) => {
                    console.error('Error:', error);
                    this.setState({buttonStatus : false});
                    toast.error('Error occured on Server!',{
                        position: "top-right",
                        autoClose: 3000,
                        progress: undefined,
                    });
                })
            )
        }
    }

    async sendUpdateForm(){
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
        const bodyData = this.state.bodyData;
        await trackPromise(
            fetch('/admin-api/transcript', {
                method: 'PUT',
                body: JSON.stringify(bodyData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                }
            }).then(response => response.json())
            .then(async (data) => {
                if(!data.success) {
                    toast.error('Error occured on Server!',{
                        position: "top-right",
                        autoClose: 3000,
                        progress: undefined,
                    });
                    this.setState({buttonStatus : false});
                    return;
                }

                // toast.success('Operation success!',{
                //     position: "top-right",
                //     autoClose: 1500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
                // await new Promise(resolve => setTimeout(resolve, 1500));
                window.location.reload(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({buttonStatus : false});
                toast.error('Error occured on Server!',{
                    position: "top-right",
                    autoClose: 3000,
                    progress: undefined,
                });
            })
        )
    }

    fieldChange(fieldId, event) {
        if (event !== undefined) {
            if (fieldId === "Email") {
                this.setState({
                    Email: event.target.value
                })
                if (event.target.value !== this.props.editData.Email) {
                    this.setState({
                        EmailChange : true
                    })
                    this.Email.current.classList.add("is-invalid");
                    this.Email.current.nextElementSibling.innerHTML = 'Attention: All the records which have old email will be updated.';
                    this.TicketNumber.current.classList.add("is-invalid");
                } else {
                    this.setState({
                        EmailChange : false,
                        TicketNumber : "",
                    })
                    this.Email.current.classList.remove("is-invalid");
                    this.Email.current.nextElementSibling.innerHTML = 'Please provide valid input.';
                    this.TicketNumber.current.classList.remove("is-invalid");
                }
            } else if (fieldId === "FirstName") {
                this.setState({
                    FirstName: event.target.value
                })
            } else if (fieldId === "LastName") {
                this.setState({
                    LastName: event.target.value
                })
            } else if (fieldId === "UniqueId") {
                this.setState({
                    UniqueId: event.target.value
                })
            }  else if (fieldId === "TicketNumber") {
                this.setState({
                    TicketNumber: event.target.value
                })
            } else if (fieldId === "DoNotLoad") {
                this.setState({
                    DoNotLoad: event.target.checked
                })
            } else if (fieldId === "MarkForDelete") {
                this.setState({
                    MarkForDelete: event.target.checked
                })
            } else if (fieldId === "Comments") {
                const commentsVal = event.target.value;
                const commentsLength = commentsVal.length;

                if(commentsLength <= 100){
                    this.setState({
                        Comments: commentsVal,
                        CommentsLength : commentsLength,
                    })
                }
            }
        }
    }

    render() {
        const {Email, DoNotLoad, MarkForDelete, Comments, FirstName, LastName, UniqueId, CommentsLength, TicketNumber, EmailChange, buttonStatus} = this.state;

        return (
            <div className="modal fade" id="edit" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" ref={this.addModal}>
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Make changes to User Attributes</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="modal-body" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="form-group">
                                <label htmlFor="emailEdit">Email</label>
                                <input className="form-control" name="Email" id="emailEdit" type="text" maxLength="100" value={Email} onChange={this.fieldChange.bind(this, "Email")} ref={this.Email}/>
                                <div className="invalid-feedback">
                                    Please provide valid input.
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailEdit">First Name</label>
                                <input type="text" className="form-control" name="FirstName" id="firstNameEdit" maxLength="100" value={FirstName} readOnly onChange={this.fieldChange.bind(this, "FirstName")} ref={this.FirstName}/>
                                <div className="invalid-feedback">
                                    Please provide valid input.
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailEdit">Last Name</label>
                                <input type="text" className="form-control" name="LastName" id="lastNameEdit" maxLength="100" value={LastName} readOnly onChange={this.fieldChange.bind(this, "LastName")} ref={this.LastName}/>
                                <div className="invalid-feedback">
                                    Please provide valid input.
                                </div>
                            </div>
                            <div className="form-group" style={{display: 'none'}}>
                                <label htmlFor="emailEdit">SSO ID</label>
                                <input type="text" className="form-control" name="UniqueId" id="ssoIdEdit" value={UniqueId} onChange={this.fieldChange.bind(this, "UniqueId")} ref={this.UniqueId} readOnly/>
                                <div className="invalid-feedback">
                                    Please provide valid input.
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="ticketEdit">Ticket Number</label>
                                <input className="form-control" name="TicketNumber" id="ticketEdit" type="text" maxLength="255" value={TicketNumber} onChange={this.fieldChange.bind(this, "TicketNumber")} ref={this.TicketNumber} readOnly={!EmailChange}/>
                                <div className="invalid-feedback">
                                    Please provide valid ServiceNow Ticket Number when you change email.
                                </div>
                            </div>
                            <hr/>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="DoNotLoad" value='1' checked={DoNotLoad} id="donotloadEdit" onChange={this.fieldChange.bind(this, "DoNotLoad")} />
                                <label className="form-check-label" htmlFor="donotloadEdit">
                                    Do Not Load to SFDC
                                </label>
                            </div>
                            <hr />
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MarkForDelete" value="1" checked={MarkForDelete} id="markfordeleteEdit" onChange={this.fieldChange.bind(this, "MarkForDelete")} />
                                <label className="form-check-label" htmlFor="markfordeleteEdit">
                                    Mark for Delete in SFDC
                                </label>
                            </div>
                            <hr />
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Notes</span>
                                </div>
                                <textarea className="form-control" name="Comments" aria-label="Comment" value={Comments} onChange={this.fieldChange.bind(this, "Comments")}></textarea>
                                <div className="input-group-append">
                                    <span className="input-group-text">{CommentsLength}/100</span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" disabled={buttonStatus}>Cancel</button>
                                <input type="submit" value="Save to Change" className="btn btn-outline-primary" disabled={buttonStatus}></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditT;