import React from 'react';
import './rowForTranscript.css'

class Row extends React.Component{

    constructor(props){
        super(props)
        this.handleEditData = this.handleEditData.bind(this);
        this.handleHistoryData = this.handleHistoryData.bind(this);
    }

    handleEditData(){
        this.props.setEditData(this.props.rowData);
    }

    handleHistoryData(){
        this.props.setHistoryData({
            TranscriptId: this.props.rowData.TranscriptId,
            AccountId: this.props.rowData.AccountId,
            CreatedOn: this.props.rowData.CreatedOn,
        });
    }

    render(){
        let rowData = this.props.rowData;
        let colToShow = ['Test', 'DateTaken', 'Email', 'FirstName', 'LastName', 'DoNotLoad', 'PassFail', 'SFDCAccreditationId', 'ExpirationDate', 'MarkForDelete', 'Comments', 'UpdatedOn'];
        let colToTransfer = ['DoNotLoad', 'MarkForDelete'];
        let dateCol = ['DateTaken', 'ExpirationDate', 'UpdatedOn'];
        let colToWrap = ['Comments'];

        return (
            <tr>
                {colToShow.map((e,i) =>
                    !colToTransfer.includes(e) ? 

                    !dateCol.includes(e) ? 
                    !colToWrap.includes(e) ? 
                    <td className="noWrapCol" key={i}>{rowData[e]}</td>
                    :
                    <td className="wrapCol" key={i}>{rowData[e]}</td> 
                    :
                    !rowData[e] ? <td className="noWrapCol" key={i}></td> : 
                    <td className="noWrapCol" key={i}> {rowData[e]} </td>
                    : 
                    <td className="noWrapCol" key={i}>{(rowData[e] === '0' || rowData[e] === 0) ? "False" : "True"}</td>
                )}
                <td className="actionCol">
                    <button type="button" className="btn btn-outline-primary actionButton" data-toggle="modal" data-target="#edit" onClick={this.handleEditData}>Edit</button>
                    <button type="button" className="btn btn-outline-primary actionButton" data-toggle="modal" data-target="#history" onClick={this.handleHistoryData}>History</button>
                </td>
            </tr>
        )
    }
}

export default Row; 