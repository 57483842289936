import React from 'react';
import './rowForLookup.css'

class row extends React.Component{

    constructor(props){
        super(props)
        this.handleEditData = this.handleEditData.bind(this);
        this.handleHistoryData = this.handleHistoryData.bind(this);
    }

    handleEditData(){
        this.props.setEditData(this.props.rowData);
    }

    handleHistoryData(){
        // console.log('rowData', this.props.rowData);
        this.props.setHistoryData({
            LookupId : this.props.rowData.LookupId,
            CreatedOn : this.props.rowData.CreatedOn,
            CreatedBy : this.props.rowData.CreatedBy,
        });
    }

    render(){
        let rowData = this.props.rowData;
        let colToShow = ['ShortTitle', 'Comment', 'LevelupCourseCode', 'BeaconCourseCode', 'BigQueryCourseCode','Validity', 'ShowScore', 'UpdatedOn'];
        let colToTransfer = ['ShowScore'];
        let colToWrap = ['Comment'];
        // rowData['UpdatedOn'] = new Date(rowData['UpdatedOn']).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        // rowData['CreatedOn'] = new Date(rowData['CreatedOn']).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });

        return (
            <tr>
                {colToShow.map((e,i) =>
                    !colToTransfer.includes(e) ?
                    colToWrap.includes(e) ? 
                    <td className="wrapCol" key={i}>{rowData[e]}</td> :
                    <td className="noWrapCol" key={i}>{rowData[e]}</td> 
                    : 
                    <td className="noWrapCol" key={i}>{(rowData[e] === '0' || rowData[e] === 0) ? "False" : "True"}</td>
                )}
                <td className="actionCol">
                    <button type="button" className="btn btn-outline-primary actionButton" data-toggle="modal" data-target="#edit" onClick={this.handleEditData}>Edit</button>
                    <button type="button" className="btn btn-outline-primary actionButton" data-toggle="modal" data-target="#history" onClick={this.handleHistoryData}>History</button>
                </td>
            </tr>
        )
    }
}

export default row;