import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppWithRouterAccess from './components/auth/AppWithRouterAccess.jsx';
import PanHeader from './components/header/header.jsx';
import './App.css';
import Loading from './components/auth/Loading.jsx';
import { ToastContainer } from "react-toastify";



function App() {

    return (
      <>
      <Loading/>
      <BrowserRouter>
        <PanHeader/>
        <AppWithRouterAccess/>
      </BrowserRouter>
      <ToastContainer/>
      </>
    );
}

export default App;
