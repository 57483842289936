import React from 'react';
import Row from '../rowForTranscript/rowForTranscript.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './tableForTranscript.css';
import Edit from '../editForTranscript/editForTranscript.jsx';
import History from '../historyForTranscript/historyForTranscript.jsx';
import $ from 'jquery';
import Pagination from '../pagination/pagination.jsx';

import { trackPromise } from 'react-promise-tracker';


class PanTable extends React.Component {

    constructor() {
        super();
        this.state = {
            fullData: [],
            pageOfItems: [],
            editData: {},
            historyData : null,
            filtedData: [],
            filter: {},
            DateTaken: null,
            ExpirationDate: null,
            UpdatedOn: null,
            findUser : "",
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.dateTakenChanged = this.dateTakenChanged.bind(this);
        this.expDateChanged = this.expDateChanged.bind(this);
        this.updateDateChanged = this.updateDateChanged.bind(this);
        this.setEditData = this.setEditData.bind(this);
        this.setHistoryData = this.setHistoryData.bind(this);
        this.findTranscriptByUserName = this.findTranscriptByUserName.bind(this);
        this.cancelFindUser = this.cancelFindUser.bind(this);
        this.findUpdate = this.findUpdate.bind(this);

        this.searchBox = React.createRef();
        this.searchButton = React.createRef();
    }

    async componentDidMount() {
        this.searchBox.current.addEventListener("keyup", (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                this.searchButton.current.click();
                }
        });
        
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        await trackPromise(
            fetch('/admin-api/transcripts',{
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                },
            })
            .then(res => res.json())
            .then(data => this.setState({
                fullData: data.data
            }, () => console.log('Full data fetched! data.data')))
        )
        this.convertTime();
        this.initFilter();
        this.filterRows();
    }

    convertTime(){
        let fullData = this.state.fullData;
        for(let ele of fullData) {
            if(ele.DateTaken) ele.DateTaken = new Date(ele.DateTaken).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
            if(ele.ExpirationDate) ele.ExpirationDate = new Date(ele.ExpirationDate).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
            if(ele.UpdatedOn) ele.UpdatedOn = new Date(ele.UpdatedOn).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
            if(ele.CreatedOn) ele.CreatedOn = new Date(ele.CreatedOn).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        }
        this.setState({ fullData : fullData});
    }

    async findTranscriptByUserName(){
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        await trackPromise(
            fetch('/admin-api/transcriptByUser?criteria=' + this.state.findUser, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                },
            })
            .then(res => res.json())
            .then(data => this.setState({
                fullData: data.data,
                // filtedData : data.data
            }, () => console.log('Search data fetched! + data.data')))
        )
        this.convertTime();
        this.filterRows();
    }

    async cancelFindUser(){
        this.setState({
            findUser:""
        });
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        await trackPromise(
            fetch('/admin-api/transcriptByUser?criteria=', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                },
            })
            .then(res => res.json())
            .then(data => this.setState({
                fullData: data.data,
                // filtedData : data.data
            }, () => console.log('Search data fetched! + data.data')))
        )
        this.convertTime();
        this.filterRows();
    }

    findUpdate(event){
        this.setState({
            findUser : event.target.value 
        })
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    setEditData(editData){
        this.setState({ editData: editData });
    }

    setHistoryData(historyData){
        this.setState({ historyData: historyData });
    }

    initFilter() {
        let filter = {};
        let dateColumn = ['DateTaken', 'ExpirationDate', 'UpdatedOn'];

        for (let key of Object.keys(this.state.fullData[0])) {
            if (!dateColumn.includes(key)) {
                filter[key] = { filterType: 'contains', filterText: '' };
            } else {
                filter[key] = { filterType: 'isBefore', filterText: '' };
            }
        }

        this.setState({ filter: filter });
    }

    dateTakenChanged(d){
        if(d){
            let newFilter = this.state.filter;
            newFilter["DateTaken"]["filterText"] = d;
            this.setState({
                DateTaken: d,
                filter: newFilter
            });
        } else {
            let newFilter = this.state.filter;
            newFilter["DateTaken"]["filterText"] = "";
            this.setState({
                DateTaken: null,
                filter: newFilter
            });
        }
        this.filterRows();
    }

    expDateChanged(d){
        if(d){
            let newFilter = this.state.filter;
            newFilter["ExpirationDate"]["filterText"] = d;
            this.setState({
                ExpirationDate: d,
                filter: newFilter
            });
        } else {
            let newFilter = this.state.filter;
            newFilter["ExpirationDate"]["filterText"] = "";
            this.setState({
                ExpirationDate: null,
                filter: newFilter
            });        
        }
        this.filterRows();
    }

    updateDateChanged(d){
        if(d){
            let newFilter = this.state.filter;
            newFilter["UpdatedOn"]["filterText"] = d;
            this.setState({
                UpdatedOn: d,
                filter: newFilter
            });
        } else {
            let newFilter = this.state.filter;
            newFilter["UpdatedOn"]["filterText"] = "";
            this.setState({
                UpdatedOn: null,
                filter: newFilter
            });        
        }
        this.filterRows();
    }

    handleInputChange(event) {
        let target = event.target;
        if (target.type === "text") {
            let filterIndex = target.getAttribute("filterindex");
            let newFilter = this.state.filter;
            let filterText = target.value.trim();
            let trueFalseColumn = ['DoNotLoad', 'MarkForDelete'];

            if (!trueFalseColumn.includes(filterIndex)) {
                //update filter
                newFilter[filterIndex]["filterText"] = filterText;
            } else {
                if (filterText.toUpperCase() === 'TRUE') {
                    newFilter[filterIndex]["filterText"] = '1';
                } else if (filterText.toUpperCase() === 'FALSE') {
                    newFilter[filterIndex]["filterText"] = '0';
                } else {
                    newFilter[filterIndex]["filterText"] = '';
                }
            }
            this.setState({
                filter: newFilter 
            });
            this.filterRows();
        } else if (target.type === "radio") {
            let filterIndex = target.getAttribute("filterindex");
            let filterType = target.value;
            let newFilter = this.state.filter;
            newFilter[filterIndex]["filterType"] = filterType;
            this.setState({
                filter: newFilter
            });
            this.filterRows();
        }
    }

    filterRows() {
        let fullData = this.state.fullData;
        let filter = this.state.filter;

        let resRow = [];
        for (let row of fullData) {
            let flag = true;
            for (let [filterKey, filterValue] of Object.entries(filter)) {
                if (filterValue.filterText === '' && filterValue.filterType !== "isEmpty") continue;
                let unitText = row[filterKey];// text at position {row, filterKey}
                if (unitText == null) unitText = '';

                if (typeof unitText === 'number') {
                    unitText = unitText.toString();
                }
                unitText = unitText.toUpperCase();
                if (filterValue.filterType === 'contains') {
                    if (!unitText.includes(filterValue.filterText.toUpperCase())) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'equals') {
                    if (unitText !== filterValue.filterText.toUpperCase()) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'isBefore') {
                    let unitDate = new Date(unitText);
                    let filterDate = new Date(filterValue.filterText);

                    if (unitDate > filterDate) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'isAfter') {
                    let unitDate = new Date(unitText);
                    let filterDate = new Date(filterValue.filterText);
                    if (unitDate < filterDate) {
                        flag = false;
                        break;
                    }
                }  else if (filterValue.filterType === 'isEmpty') {
                    if (unitText !== null && unitText.trim() !== '') {
                        flag = false;
                        break;
                    }
                }
            }

            if (flag) {
                resRow.push(row);
            }
        }
        this.setState({ filtedData: resRow });
    }

    render() {
        //$('[data-toggle="tooltip"]').tooltip();

        let pageOfItems = this.state.pageOfItems;
        let filter = this.state.filter;
        let filtedData = this.state.filtedData;
        let editData = this.state.editData;
        let historyData = this.state.historyData;
        let findUser = this.state.findUser;

        let title = [], columnKey = [], dateColumn = [];
        if(Object.entries(filter).length !== 0){
            title = ['Test Name', 'Test Date', 'Email', 'First Name', 'Last Name', 'Do Not Load to SFDC', 'Passed/Failed', 'SFDC Accreditation ID', 'Expiration Date', 'Mark for Delete In SFDC', 'Notes', 'Last Updated'];
            columnKey = ['Test', 'DateTaken', 'Email', 'FirstName', 'LastName', 'DoNotLoad', 'PassFail', 'SFDCAccreditationId', 'ExpirationDate', 'MarkForDelete', 'Comments', 'UpdatedOn'];
            dateColumn = ['DateTaken', 'ExpirationDate', 'UpdatedOn'];
        }
        return (
            <>
                <div className="find">
                    <div className='info' data-toggle="tooltip" data-placement="left" title="This table only shows the most recent 1000 transcript records in database.">
                        <img className='info-img' src="info-circle-solid.svg" alt="info"/>
                    </div>
                    <div className="input-group findUser">
                        <div className='info' data-toggle="tooltip" data-placement="left" title="This tool will search all the records in database by keyword in Name or Email">
                            <img className='info-img' src="info-circle-solid.svg" alt="info"/>
                        </div>
                        <input type="text" className="form-control" placeholder="Find user" id="searchBox" onChange={this.findUpdate} value={findUser} ref={this.searchBox}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" id="searchButton" type="button" onClick={this.findTranscriptByUserName} ref={this.searchButton}>Search</button>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.cancelFindUser}>Reset</button>
                        </div>
                    </div>
                </div>
                <div className="panTable">
                    <div className="panTableBody">
                        <History historyData={historyData}></History>
                        <Edit editData={editData}></Edit>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                {columnKey.map((ele, index) =>

                                    !dateColumn.includes(ele) ? 
                                        <th scope="col" key={index}>
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={this.handleInputChange} filterindex={ele}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                    <div className="dropdown-menu">
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio1'} value="contains" checked={filter[ele].filterType === "contains"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio1'}>
                                                                Contains
                                                            </label>
                                                        </div>
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio2'} value="equals" checked={filter[ele].filterType === "equals"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio2'}>
                                                                Equals to
                                                            </label>
                                                        </div>
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio3'} value="isEmpty" checked={filter[ele].filterType === "isEmpty"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio3'}>
                                                                Is Empty
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        : 
                                        <th scope="col" key={index}>
                                            <div className="date-input-group">

                                                <DatePicker className="form-control"
                                                    selected={this.state[ele]}
                                                    onChange={ele === 'DateTaken' ? this.dateTakenChanged : ele === 'ExpirationDate' ? this.expDateChanged : this.updateDateChanged}
                                                    dateFormat="MM/dd/yyyy"
                                                    isClearable
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                    <div className="dropdown-menu">
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio1'} value="isBefore" checked={filter[ele].filterType === "isBefore"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio1'}>
                                                                Is Before
                                                        </label>
                                                        </div>
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio2'} value="isAfter" checked={filter[ele].filterType === "isAfter"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio2'}>
                                                                Is After
                                                            </label>
                                                        </div>
                                                        <div className="form-check dropdown-item">
                                                            <input className="form-check-input" type="radio" id={ele + 'radio3'} value="isEmpty" checked={filter[ele].filterType === "isEmpty"} onChange={this.handleInputChange} filterindex={ele} />
                                                            <label className="form-check-label" htmlFor={ele + 'radio3'}>
                                                                Is Empty
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    <th scope="col"></th>
                                </tr>
                                <tr>
                                    {title.map((ele, index) =>
                                        <th scope="col" key={index} className="tableCol">{ele}</th>
                                    )}
                                    <th scope="col" className="tableCol">Actions</th>
                                </tr>
                            </thead>
                                <tbody className="table-body">
                                    {pageOfItems.map((ele, index) =>
                                        <Row key={index} rowData={ele} setEditData={this.setEditData} setHistoryData={this.setHistoryData}/>
                                    )} 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination className="pagination" items={filtedData} onChangePage={this.onChangePage}/>
                </div>
            </>
        )
    }

}

export default PanTable;
