import React from 'react';
import TranTable from '../tableForTranscript/tableForTranscript.jsx';
import { withOktaAuth } from '@okta/okta-react';
import { trackPromise } from 'react-promise-tracker';

export default withOktaAuth(
    class Transcript extends React.Component{

        constructor(props){
            super(props);

            this.state = {
                isUserAuth : 0
            }
        }

        async componentDidMount(){
            const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
            // console.log('token-transcript:', JSON.stringify(idToken));
            // console.log('token transcript:', idToken.accessToken.accessToken);

            let requestPath = '/admin-api/user?email=' + idToken.idToken.claims.email;
            await trackPromise(
                fetch(requestPath,{
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                        },
                    })
                    .then(res => {
                        console.log('res newest', res);
                        if(res.status === 401){
                            // this.setState({
                            //     isUserAuth : 3
                            // })
                            window.location.reload(false);

                            // window.location.replace('/login');
                        } else if (res.status === 404){
                            this.setState({
                                isUserAuth : 2
                            })
                        } else if (res.status === 200){
                            this.setState({
                                isUserAuth : 1
                            })
                        }
                        // return res.json();
                    })
                    .catch((err) => {
                        console.error(err);
                        window.location.reload(false);
                    })
                    // .then(data => this.setState({
                    //     isUserAuth: data.success ? 1 : 2
                    // }, () => console.log('Email Verified: + data')))
            );
            // if(this.state.isUserAuth === 3){
            //     window.location.reload(false);
            // }
        }

        render(){
            if(this.state.isUserAuth === 1) {
                return (
                    <div className="container-fluid">
                    {/* <PanHeader/> */}
                    <TranTable/>
                </div>)
            } else if (this.state.isUserAuth === 2){
                return (
                    <div className="container">
                        <h1>Unauthorized with current login credential.</h1>
                        <a className="btn btn-primary" role="button" href="/login" onClick={() => {this.props.oktaAuth.signOut();}}>Go back to Login</a>
                    </div>
                )
            } else if (this.state.isUserAuth === 0){
                return (
                    <div className="container">
                        <h1>Loading...</h1>
                    </div>
                )
            }
        }
    }
)