import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import ExamLookup from '../examLookup/examLookup.jsx';
import Transcript from '../transcript/transcript.jsx';
import Login from './Login.jsx';
import { trackPromise } from 'react-promise-tracker';

export default withRouter(class AppWithRouterAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      okta : null
    }
    this.onAuthRequired = this.onAuthRequired.bind(this);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  async componentDidMount(){
    await trackPromise(
      fetch('/admin-api/getOktaClient')
      .then(res => res.json())
      .then(data => this.setState({
        okta: data.data
      }))
    )
  }

  onAuthRequired() {
    this.props.history.push('/login');
  }

  render() {
    let okta = this.state.okta;
    
    if(okta){
     
      var config = {
        issuer: okta.ISSUER,
        clientId: okta.CLIENT_ID,
        redirectUri: window.location.origin + '/implicit/callback',
      };
      const oktaAuth = new OktaAuth(config);
      return (  
          <Security
            oktaAuth={oktaAuth}
            onAuthRequired={this.onAuthRequired}
            restoreOriginalUri={this.restoreOriginalUri}
          >
            <Route path="/" exact={true} render={() => (<Login baseUrl={okta.ISSUER}/>)} />
            <SecureRoute path="/examlookup" exact={true} component={ExamLookup} />
            <SecureRoute path="/transcript" exact={true} component={Transcript} />
            <Route path="/login" render={() => (<Login baseUrl={okta.ISSUER} />)}/>
            <Route path="/implicit/callback" component={LoginCallback} />
          </Security>
      );
    } else {
      return (
        <div>
          {/* <h5>Trying to get Okta Client Id...</h5> */}
        </div>
      );
    }
  }
});