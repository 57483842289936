import React from 'react';
import './mergeNotification.css'
import "react-toastify/dist/ReactToastify.css";

const MergeNotification = (props) => {

    return (
            <div>
                <p>Your new email exists in system. Do you want to merge the Account?</p>
                <button type="button" className="btn btn-outline-light btn-sm" onClick={props.sendUpdateForm}>Yes</button>
                <button type="button" className="btn btn-outline-light btn-sm" onClick={props.closeToast}>No</button>
            </div>
    )
};

export default MergeNotification;