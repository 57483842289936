import React from 'react';
import './Loading.css';
import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    
    return (
        promiseInProgress && 
        <div className="loading">Loading&#8230;</div>
    );  
}

export default LoadingIndicator;